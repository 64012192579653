export default [
  {
    src: "/images/gallery/memento1.jpg",
    width: 3,
    height: 2,
  },
  {
    src: "/images/gallery/memento2.jpg",
    width: 3,
    height: 2,
  },
  {
    src: "/images/gallery/memento3.jpg",
    width: 3,
    height: 2,
  },
  {
    src: "/images/gallery/memento4.jpg",
    width: 3,
    height: 2,
  },
  {
    src: "/images/gallery/memento5.jpg",
    width: 3,
    height: 2,
  },
  {
    src: "/images/gallery/memento7.jpg",
    width: 3,
    height: 2,
  },
  {
    src: "/images/gallery/memento8.jpg",
    width: 3,
    height: 2,
  },
  {
    src: "/images/gallery/memento9.jpg",
    width: 3,
    height: 2,
  },
  {
    src: "/images/gallery/memento10.jpg",
    width: 3,
    height: 2,
  },
  {
    src: "/images/gallery/memento11.jpg",
    width: 3,
    height: 2,
  },
  {
    src: "/images/gallery/memento12.jpg",
    width: 3,
    height: 2,
  },
  {
    src: "/images/gallery/memento13.jpg",
    width: 3,
    height: 2,
  },
  {
    src: "/images/gallery/memento14.jpg",
    width: 3,
    height: 2,
  },
  {
    src: "/images/gallery/memento15.jpg",
    width: 3,
    height: 2,
  },
  {
    src: "/images/gallery/memento16.jpg",
    width: 3,
    height: 2,
  },
  {
    src: "/images/gallery/memento17.jpg",
    width: 3,
    height: 2,
  },
  {
    src: "/images/gallery/memento18.jpg",
    width: 3,
    height: 2,
  },
  {
    src: "/images/gallery/memento19.jpg",
    width: 3,
    height: 2,
  },
  {
    src: "/images/gallery/memento20.jpg",
    width: 3,
    height: 2,
  },
  {
    src: "/images/gallery/memento21.jpg",
    width: 3,
    height: 2,
  },
  {
    src: "/images/gallery/memento22.png",
    width: 2,
    height: 3,
  },
  {
    src: "/images/gallery/memento23.jpg",
    width: 3,
    height: 2,
  },
  {
    src: "/images/gallery/memento24.jpg",
    width: 3,
    height: 2,
  },
  {
    src: "/images/gallery/memento25.jpg",
    width: 3,
    height: 2,
  },
]
